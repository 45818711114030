// import { Visibility, VisibilityOff } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
// import { encryptPreHashed } from "../../../../Utils/encyption";
import Axios from "axios";

// import { toast } from "react-toastify";
// import { useAuth } from "../../../../Utils/Auth";
// import { CircularProgress } from "@mui/material";
// import UpIntradayIcon from "../../../../Assets/Icons/UptrendIcon";
import localNumber from "../../../../Utils/localnumber";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  // LineChart,
  // Line,
  ResponsiveContainer,
  Pie,
  PieChart,
  Sector,
} from "recharts";
import axios from "axios";
import ChangeFormat from "../../../../Utils/ChangeFormat";

// const order = [
//   "New Lead",
//   "1st Contact",
//   "Initial Meet",
//   "Test Account",
//   "Offer Placed",
//   "Offer Accepted",
//   "Invoice Sent",
//   "Money Received",
//   "Customer Nurturing",
//   "Declined",
//   "Lost",
//   "No Potential",
// ];
const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload._id} ({value})
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${value}`}</text>
    </g>
  );
};
export default function Revenues() {
  // const { setcallSuperAdmin, callSuperAdmin, superAdmin } = useAuth();
  // const [password, setPassword] = useState("");
  const [activeIndex, setactiveIndex] = useState(0);

  // const [values, setValues] = useState({
  //   showPassword: false,
  // });
  // const [disable, setdisable] = useState(false);
  // const [otpStatus, setotpStatus] = useState(null);
  // const [code, setCode] = useState(null);
  // const [isLoader, setisLoader] = useState(false);
  // const handleClickShowPassword = () => {
  //   setValues({ ...values, showPassword: !values.showPassword });
  // };
  const [diffStages, setdiffStages] = useState([]);

  // const handleOnSubmit = async (e) => {
  //   e.preventDefault();
  //   // if (isNewDevice || otpStatus === "sent") {
  //   //   verifyOtp();
  //   //   return;
  //   // }

  //   setdisable(true);
  //   const data = {
  //     password: encryptPreHashed(password),
  //   };
  //   console.log(encryptPreHashed(password));

  //   try {
  //     let res = await Axios.post(`https://coral-app-s2kbc.ondigitalocean.app/api/admin/login`, data);
  //     if (res.data.success === true) {
  //       setotpStatus("sent");
  //       toast.success("Enter Security PIN");
  //     }
  //   } catch (error) {
  //     toast.error(error.message);
  //   }
  // };

  // const verifyOtp = async () => {
  //   if (!code) {
  //     toast.error("Please Enter Valid OTP", { position: "bottom-right" });
  //   } else {
  //     setisLoader(true);
  //     Axios({
  //       method: "POST",
  //       url: `https://coral-app-s2kbc.ondigitalocean.app//api/admin/login-otp-verify`,
  //       data: {
  //         code,
  //       },
  //     })
  //       .then((response) => {
  //         if (response.data.status === "approved") {
  //           setcallSuperAdmin(!callSuperAdmin);

  //           toast.success("Access Granted", { position: "bottom-right" });
  //         } else if (response.data.status === "pending") {
  //           toast.error("Invalid OTP", { position: "bottom-right" });
  //           setisLoader(false);
  //         } else {
  //           toast.error("Something went wrong", { position: "bottom-right" });
  //           setisLoader(false);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error.message);
  //         toast.error("Something went wrong", { position: "bottom-right" });
  //       });
  //   }
  // };

  // const handleotpchange = (check6digit) => {
  //   setCode(check6digit.slice(0, 6));
  // };

  // React.useEffect(() => {
  //   if (code && code.length === 6) {
  //     verifyOtp();
  //   }
  // }, [code]);

  const [revenuestats, setrevenuestats] = useState();
  const [expectedRevenue, setexpectedRevenue] = useState(0);
  const [year, setyear] = useState("All");
  const [compareRevenue, setcompareRevenue] = useState([]);
  const getComparedRevenue = async () => {
    try {
      try {
        let res = await Axios.get(`/api/sale/comparepastyearrevenue`);
        if (res.data.success === true) {
          setcompareRevenue(res.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [, setmonthlyRevenue] = useState([]);
  const getMonthlyRevenue = async () => {
    try {
      try {
        let res = await Axios.get(`/api/sale/monthlyrevenues?year=${year}`);
        if (res.data.success === true) {
          setmonthlyRevenue(res.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRevenueStats = async () => {
    try {
      const response = await axios.get(`/api/sale/revenuestats?year=${year}`);
      if (response.data.success) {
        setrevenuestats(response.data.data);
        setdiffStages(response.data.data.totalCustomers.slice(0, -1));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getExpectedRevenue = async () => {
    try {
      const response = await axios.get(`/api/sale/totalrevenue?year=${year}`);
      if (response.data.success) {
        setexpectedRevenue(response.data.totalrevenue);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getRevenueStats();
    getExpectedRevenue();
    getComparedRevenue();
    getMonthlyRevenue();
  }, [year]);

  return (
    <div>
      {/* {!superAdmin ? (
        // <div className="text_align_center mt-5">
        //   <h4>Enter Security Password</h4>
        //   <label className="auth_label w-40">
        //     <input
        //       className="auth_input auth_input_password"
        //       placeholder="Enter your password"
        //       required
        //       type={!values.showPassword ? "password" : "text"}
        //       value={password}
        //       disable={disable}
        //       onChange={(e) => setPassword(e.target.value)}
        //     />
        //     <button
        //       className="auth_input_pas_btn"
        //       type="button"
        //       onClick={() => handleClickShowPassword()}
        //     >
        //       {values.showPassword ? <Visibility /> : <VisibilityOff />}
        //     </button>
        //   </label>
        //   {otpStatus === "sent" ? (
        //     <>
        //       <br />
        //       <input
        //         type="number"
        //         className="auth_input w-40 black"
        //         placeholder="OTP"
        //         value={code}
        //         onChange={(e) => handleotpchange(e.target.value)}
        //       />
        //     </>
        //   ) : null}
        //   <br />
        //   {otpStatus === "sent" ? (
        //     <button
        //       className="gradient_admin_btn mt-16 px-3"
        //       onClick={(e) => handleOnSubmit(e)}
        //     >
        //       SUBMIT CODE
        //     </button>
        //   ) : (
        //     <button
        //       disabled={isLoader}
        //       className="gradient_admin_btn mt-16 px-3"
        //       onClick={(e) => handleOnSubmit(e)}
        //     >
        //       SUBMIT
        //     </button>
        //   )}

        //   {isLoader ? (
        //     <div className="mt-5">
        //       <CircularProgress />
        //     </div>
        //   ) : null}
        // </div>
      ) : ( */}
      {revenuestats && (
        <div className="">
          <div>
            <div className="d-flex justify_btwn pb-4">
              <h4>Revenues</h4>
              <select
                value={year}
                onChange={(e) => setyear(e.target.value)}
                className="me-3 w-120px"
              >
                <option value={2024}>2024</option>
                <option value={2023}>2023</option>
                <option value={2022}>2022</option>
                <option value="All">All</option>
              </select>
            </div>
            <div className="row ms-0 me-0">
              <div className="col-3 ps-0">
                <div className="admin_box">
                  <div className="d-flex justify_btwn">
                    <p className="p_nunito_18_black">Total Revenue (Actual)</p>
                    <p className="p_nunito_18_black">{year}</p>
                  </div>
                  <h4 className="h2_black_40">
                    €{" "}
                    {localNumber(revenuestats.totalRevenue[0]?.totalRevenueSum)}
                  </h4>
                  {/* <p className="green mb-0">
                    <UpIntradayIcon cc={"#00915B"} />
                    20%
                  </p> */}
                </div>
              </div>

              <div className="col-2">
                <div className="admin_box">
                  <p className="p_nunito_18_black">Subscriptions</p>
                  <h4 className="h2_black_40">{revenuestats.subscriptions}</h4>
                  {/* <p className="green mb-0">
                    <UpIntradayIcon cc={"#00915B"} />
                    15%
                  </p> */}
                </div>
              </div>
              <div className="col-2">
                <div className="admin_box">
                  <p className="p_nunito_18_black">Expected Revenue</p>
                  <h4 className="h2_black_40">
                    € {localNumber(expectedRevenue)}
                  </h4>
                  {/* <p className="green mb-0">
                    <UpIntradayIcon cc={"#00915B"} />
                    15%
                  </p> */}
                </div>
              </div>

              {/* <div className="col-3">
                  <div className="admin_box">
                    <p className="p_nunito_18_black">Top Paying Customers</p>
                    {revenuestats?.toppaying?.map((v, i) => {
                      return (
                        <>
                          <div
                            key={i}
                            className="d-flex ms-0 me-0 justify_btwn mt-4"
                          >
                            <p className=" mb-0">{v.name}</p>
                            <span className="mb-0">
                              €{localNumber(v.totalRevenueSum)}
                            </span>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div> */}
            </div>

            <div className="mt-4 d-flex justify-content-between">
              <div className="col-6">
                <h3>Revenues</h3>
                <ResponsiveContainer height={400}>
                  <BarChart
                    // width={700}
                    // height={400}
                    data={compareRevenue}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 0,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis
                      yAxisId="left"
                      orientation="left"
                      stroke="#8884d8"
                      tickFormatter={(tick) => `€${ChangeFormat(tick)} `}
                    />
                    <YAxis
                      yAxisId="right"
                      orientation="right"
                      stroke="#82ca9d"
                      tickFormatter={(tick) => `€${ChangeFormat(tick)} `}
                    />
                    <Tooltip />
                    <Legend />
                    <Bar
                      yAxisId="left"
                      dataKey={new Date().getFullYear()}
                      fill="#8884d8"
                    />
                    <Bar
                      yAxisId="right"
                      dataKey={new Date().getFullYear() - 1}
                      fill="#82ca9d"
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <div className="col-4">
                <h3>
                  Total Customers {revenuestats?.totalCustomers[12]?.totalcount}
                </h3>

                <div className="admin_box mt-10">
                  <PieChart width={520} height={350}>
                    <Pie
                      activeIndex={activeIndex}
                      activeShape={renderActiveShape}
                      data={diffStages}
                      cx="50%"
                      cy="50%"
                      innerRadius={85}
                      outerRadius={120}
                      fill="#4ad7d1"
                      dataKey="totalcount"
                      onMouseEnter={(_, i) => setactiveIndex(i)}
                    />
                  </PieChart>
                </div>
              </div>

              {/* <div className="col-6">
                  <h3>MRR</h3>
                  <ResponsiveContainer height={400}>
                    <LineChart
                      // width={700}
                      // height={400}
                      data={monthlyRevenue}
                      margin={{
                        top: 5,
                        right: 30,
                        left: -10,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey={"month"} />
                      <YAxis />
                      <Tooltip
                        contentStyle={{
                          color: "black",
                          backgroundColor: "white",
                          borderRadius: 5,
                        }}
                      />
                      <Legend />
                      <Line
                        // type="monotone"
                        dataKey="revenues"
                        stroke="#8884d8"
                        strokeWidth="3"
                        activeDot={{ r: 8 }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div> */}
            </div>

            {/* <h6>Total Revenue</h6>
            <p>EUR 4.233.342,80 </p>
            <h6>Revenue Chart</h6> */}
          </div>
          {/* <div>MRR</div>
          <div>No. of Customers</div>
          <div>Invoices</div>
          <div>Quotations</div>
          <div>Revenues</div> */}
        </div>
      )}
      {/* /* ) */}
      {/* )} */}
    </div>
  );
}
