import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import z_logo from "../../Assets/Images/Zefyron_logo_black_W400px.png";

import "./index.css";
import Axios from "axios";
// import { Notyf } from "notyf";
import { CircularProgress } from "@mui/material";
import { ArrowLeft } from "@mui/icons-material";
import { useAuth } from "../../Utils/Auth";
import { toast } from "react-toastify";
// import CircularProgress from "@mui/material/CircularProgress";
// import { toast } from "react-toastify";

const AdminDashboard = () => {
  const history = useNavigate();
  const [startup, setStartup] = useState();
  const [investors, setInvestors] = useState();
  const [fundCompanies, setfundCompanies] = useState();
  const [corporates, setcorporates] = useState();
  // const [isLoad, setisLoad] = useState(false);
  const [twbalance, settwbalance] = useState();
  const { setcallSuperAdmin } = useAuth();

  const fetchStats = async () => {
    const { data } = await Axios({
      method: "get",
      url: "/api/admin/stats",
    });
    const { Investors, Startups, FundCompanies, Corporates } = data;
    setStartup(Startups);
    setInvestors(Investors);
    setfundCompanies(FundCompanies);
    setcorporates(Corporates);
  };
  const fetchTwilio = async () => {
    const { data } = await Axios({
      method: "get",
      url: "/api/admin/twilio-balance",
    });
    const { balance } = data;
    console.log(data);
    settwbalance(balance);
  };
  useEffect(() => {
    fetchStats();
    fetchTwilio();
  }, []);

  // if (!startup) {
  //   return "empty";
  // }
  // if (!investors) {
  //   return "empty";
  // }
  // if (!corporates) {
  //   return "empty";
  // }
  // if (!fundCompanies) {
  //   return "empty";
  // }
  // if (!twbalance) {
  //   return "empty";
  // }

  const gotoVerifyFounders = () => {
    history("/admin/verify-founder");
  };
  const gotoVerifyInvestor = () => {
    history("/admin/verify-investor");
  };
  const gotoVerifyFundCompany = () => {
    history("/admin/verify-fundcompany");
  };
  // const gotoVerifyFundManager = () => {
  //   history("/admin/verify-fundmanager");
  // };
  const gotoSendNotify = () => {
    history("/admin/notification");
  };
  const logout = async () => {
    await Axios("/api/admin/logout")
      .then(() => {
        toast.success("Logged Out");
        history("/");
      })
      .catch(() => toast.error("Some Error"));
  };
  const gotoVerifyCorporate = () => {
    history("/admin/verify-corporate");
  };
  // const handleMarketStartup = async () => {
  //   if (confirm("Press a button!\nEither OK or Cancel.") === true) {
  //     setisLoad(true);
  //     await Axios({
  //       url: "/api/admin/marketing/startup-users",
  //       method: "post",
  //     }).then(() => {
  //       setisLoad(false);
  //       toast.success("Sent Mails!");
  //     });
  //   } else {
  //     return;
  //   }
  // };
  // const handleMarketInvestor = async () => {
  //   if (confirm("Press a button!\nEither OK or Cancel.") === true) {
  //     setisLoad(true);
  //     await Axios({
  //       url: "/api/admin/marketing/fundCompany-users",
  //       method: "post",
  //     }).then(() => {
  //       setisLoad(false);
  //       toast.success("Sent Mails!");
  //     });
  //   } else {
  //     return;
  //   }
  // };
  // const handleMarketCorporate = async () => {
  //   if (confirm("Press a button!\nEither OK or Cancel.") === true) {
  //     setisLoad(true);
  //     await Axios({
  //       url: "/api/admin/marketing/corporate-users",
  //       method: "post",
  //     }).then(() => {
  //       setisLoad(false);
  //       toast.success("Sent Mails!");
  //     });
  //   } else {
  //     return;
  //   }
  // };
  // const handleMarketAcademia = async () => {
  //   if (confirm("Press a button!\nEither OK or Cancel.") === true) {
  //     setisLoad(true);
  //     await Axios({
  //       url: "/api/admin/marketing/academia-users",
  //       method: "post",
  //     }).then(() => {
  //       setisLoad(false);
  //       toast.success("Sent Mails!");
  //     });
  //   } else {
  //     return;
  //   }
  // };

  return (
    <>
      {!startup || !investors || !corporates || !fundCompanies || !twbalance ? (
        <div className="v_center">
          <CircularProgress />
          <Link to="/">
            <button className="gradient_admin_btn m-4 ms-5">
              <ArrowLeft />
              Back to Login
            </button>
          </Link>
        </div>
      ) : (
        <div className="admin-root container">
          <div className="d-flex mt-2 align-items-center">
            <img src={z_logo} className="h-40px" alt="logo" />

            <button
              className="gradient_admin_btn w-120px ms-auto"
              onClick={logout}
            >
              LOGOUT
            </button>
          </div>
          <div className="row align-items-end mt-5">
            <div className="col-3 ps-0">
              <h1
                className="gradient_admin_btn"
                onClick={() => history("/admin/user-activity")}
              >
                Check User Activity
              </h1>
            </div>
            <div className="col-3">
              <h1
                className="gradient_admin_btn"
                onClick={() => {
                  setcallSuperAdmin(true);
                  history("/admin/sales");
                }}
              >
                Sales CRM
              </h1>
            </div>
          </div>
          <div className="d-flex ">
            <div className="d-flex align-items-start">
              <div className="leftStats me-3">
                <div className="leftReq">
                  <div className="totalReq">
                    <h3 className="black">New Requests</h3>
                    <div className="row">
                      <button
                        className="gradient_admin_btn col-5 mt-2"
                        onClick={gotoVerifyFounders}
                      >
                        Startups requests
                      </button>
                      <button
                        className="gradient_admin_btn col-5 mt-2"
                        onClick={gotoVerifyInvestor}
                      >
                        Investor requests
                      </button>

                      <button
                        className="gradient_admin_btn col-5 mt-2"
                        onClick={gotoVerifyFundCompany}
                      >
                        Fund requests
                      </button>
                      {/* <button
                  className="gradient_admin_btn col-5 mt-2"
                  onClick={gotoVerifyFundManager}
                >
                  Fund Manager requests
                </button> */}
                      <button
                        className="gradient_admin_btn col-5 mt-2"
                        onClick={gotoVerifyCorporate}
                      >
                        Corporate Requests
                      </button>
                    </div>
                  </div>
                  <div className="totalReq">
                    <h3> Corporates</h3>
                    <div className="row mx-0">
                      <div className="col-5 w-40">
                        <button
                          className="gradient_admin_btn w-100  mt-2 "
                          onClick={() => {
                            history("/admin/corporate");
                          }}
                        >
                          Onboard
                        </button>
                      </div>
                      <div className="col-5 w-40">
                        <button
                          className="gradient_admin_btn w-100 mt-2 "
                          onClick={() => {
                            history("/admin/all-corporates");
                          }}
                        >
                          All Corporates
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="totalReq">
                    <h3> Fund Companies</h3>
                    <div className="row mx-0">
                      <div className="col-5 w-40">
                        <button
                          className="gradient_admin_btn w-100  mt-2 "
                          onClick={() => {
                            history("/admin/funds");
                          }}
                        >
                          Onboard
                        </button>
                      </div>
                      <div className="col-5 w-40">
                        <button
                          className="gradient_admin_btn w-100 mt-2 "
                          onClick={() => {
                            history("/admin/all-funds");
                          }}
                        >
                          All Funds
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="totalReq">
                    <h3> Academia</h3>
                    <div className="row">
                      <button
                        className="gradient_admin_btn col-5 mt-2"
                        onClick={() => {
                          history("/admin/academia");
                        }}
                      >
                        Onboard
                      </button>
                    </div>
                  </div>
                  {/* <div className="totalReq">
                    <h3> Latest News</h3>
                    <div className="row">
                      <button
                        className="gradient_admin_btn col-5 mt-2"
                        onClick={() => {
                          history("/admin/latestNews");
                        }}
                      >
                        News Form
                      </button>
                    </div>
                  </div> */}

                  {/* <div className="Req">
                    <h3>Valuation Requests</h3>
                    <button
                      onClick={() => history("/admin/valuation")}
                      className="gradient_admin_btn"
                    >
                      View all valuation requests
                    </button>
                  </div> */}
                  {/* <div className="Req">
                    <h3>Pitch Deck Tool</h3>
                    <button
                      className="gradient_admin_btn"
                      onClick={() => history("/admin/pitchdeck")}
                    >
                      Manage Pitch Deck
                    </button>
                  </div> */}

                  <div className="totalReq mb-4">
                    <h3>Admin Notifications</h3>
                    <button
                      onClick={gotoSendNotify}
                      className="gradient_admin_btn"
                    >
                      Send Notifications{" "}
                    </button>
                  </div>
                </div>
              </div>

              <div className="rightStats">
                <div className="statsTitle">
                  <h2 className="h2_white_30">STATISTICS</h2>
                </div>
                <div className="boxed_statics_info mt-1">
                  <div className="statsCards">
                    <div className="statsCell">
                      <i className="fas fa-user-shield"></i>
                      <h3>{startup.total}</h3>
                      <h6>Startups</h6>
                    </div>
                    <div className="statsCell">
                      <i className="fas fa-user-check"></i>
                      <h3>{startup.verified}</h3>
                      <h6>Verified Startups</h6>
                    </div>
                    <div className="statsCell">
                      <i className="fas fa-users-slash"></i>
                      <h3>{startup.nonverified}</h3>
                      <h6>Non Verified Startups</h6>
                    </div>
                    <div className="statsCell">
                      <i className="fas fa-user-tie"></i>
                      <h3>{investors.total}</h3>
                      <h6>Investors</h6>
                    </div>
                    <div className="statsCell">
                      <i className="fas fa-users"></i>
                      <h3>{investors.verified}</h3>
                      <h6>Verified Investors</h6>
                    </div>
                    <div className="statsCell">
                      <i className="fas fa-user-slash"></i>
                      <h3>{investors.nonverified}</h3>
                      <h6>Non Verified Investors</h6>
                    </div>
                    <div className="statsCell">
                      <i className="fas fa-user-shield"></i>
                      <h3>{fundCompanies.total}</h3>
                      <h6>Fund Companies</h6>
                    </div>
                    <div className="statsCell">
                      <i className="fas fa-user-check"></i>
                      <h3>{fundCompanies.verified}</h3>
                      <h6>Verified Fund Companies</h6>
                    </div>
                    <div className="statsCell">
                      <i className="fas fa-users-slash"></i>
                      <h3>{fundCompanies.nonverified}</h3>
                      <h6>Non Verified Fund Companies</h6>
                    </div>
                    <div className="statsCell">
                      <i className="fas fa-user-shield"></i>
                      <h3>{corporates.total}</h3>
                      <h6>Corporates</h6>
                    </div>
                    <div className="statsCell">
                      <i className="fas fa-user-check"></i>
                      <h3>{corporates.verified}</h3>
                      <h6>Verified Corporates</h6>
                    </div>
                    <div className="statsCell">
                      <i className="fas fa-users-slash"></i>
                      <h3>{corporates.nonverified}</h3>
                      <h6>Non Verified Corporates</h6>
                    </div>
                  </div>
                </div>
                <div className="d-flex w-100">
                  <div className="leftStats me-3">
                    <div className="totalReq">
                      <h3>Twilio Balance</h3>
                      <h1 className="gradient_admin_btn">{twbalance} </h1>
                    </div>
                  </div>

                  <div className="leftStats me-3">
                    <div className="totalReq">
                      <h3>Platform Updates</h3>
                      <button
                        onClick={() => history("/admin/platformupdates")}
                        className="gradient_admin_btn"
                      >
                        Send Updates{" "}
                      </button>
                    </div>
                  </div>
                  <div className="leftStats">
                    <div className="totalReq">
                      <h3>Admin Notifications</h3>
                      <button
                        onClick={gotoSendNotify}
                        className="gradient_admin_btn "
                      >
                        Send Notifications{" "}
                      </button>
                    </div>
                  </div>
                </div>

                {/* <div className="white">
            <h2 className="h2_white_30">MARKETING</h2>
            <div className="row">
              <button
                className="gradient_CTA px-4"
                disabled={isLoad}
                onClick={() => handleMarketStartup()}
              >
                SEND MAIL TO STARTUP
              </button>
              <button
                className="gradient_CTA px-4"
                disabled={isLoad}
                onClick={() => handleMarketInvestor()}
              >
                SEND MAIL TO INVESTOR
              </button>
              <button
                className="gradient_CTA px-4"
                disabled={isLoad}
                onClick={() => handleMarketCorporate()}
              >
                SEND MAIL TO CORPORATE
              </button>
              <button
                className="gradient_CTA px-4"
                disabled={isLoad}
                onClick={() => handleMarketAcademia()}
              >
                SEND MAIL TO ACADEMIA
              </button>
            </div>
            {isLoad ? <CircularProgress /> : null}
          </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminDashboard;
