import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Current from "./Current";
import Form from "./Form";
import { useNavigate } from "react-router-dom";
import Users from "./Users";
import Revenues from "./Revenues";
import Invoices from "./Invoices";
// import { useAuth } from "../../../Utils/Auth";
import axios from "axios";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
export default function Sales() {
  const [value, setValue] = React.useState(0);
  const [leads, setleads] = React.useState([]);

  const history = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getLeads = async () => {
    await axios({
      method: "get",
      url: "/api/sale/all-users",
    })
      .then((res) => {
        if (res.data.success) {
          setleads(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  React.useEffect(() => {
    getLeads();
  }, []);

  return (
    <div>
      <div className="d-flex px-1 justify-content-between pt-4 px-3">
        <div className="ms-4">
          <h2 className="white ">Sales CRM</h2>
        </div>
        <div className="ms-auto pointer width_fit ">
          <h1 className="gradient_admin_btn" onClick={() => setValue(1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-left"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
              />
            </svg>
            {"  "}
            Back to Dashboard
          </h1>
        </div>
        <div className=" pointer width_fit ms-2">
          <h1
            className="gradient_admin_btn"
            onClick={() => history("/admin/user-activity")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-left"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
              />
            </svg>
            {"  "}
            User Activity
          </h1>
        </div>
      </div>
      <Box
        sx={{
          flexGrow: 1,
          display: "",
        }}
        className="dashboard01_main_box"
      >
        <Tabs
          //   orientation="ho"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
          className="dashboard01_tabs"
        >
          <Tab label={<p>Users</p>} {...a11yProps(0)} />

          <Tab
            label={
              <p onClick={() => history("/admin/sales")}>Current Status</p>
            }
            {...a11yProps(1)}
          />
          <Tab label={<p>Add Data</p>} {...a11yProps(2)} />
          <Tab label={<p>Revenues</p>} {...a11yProps(3)} />
          <Tab label={<p>Invoices</p>} {...a11yProps(4)} />
        </Tabs>
        <div className="" style={{ width: "100%" }}>
          <TabPanel value={value} index={0} className="dashboard01_tabpanel">
            <Users setValue={setValue} value={value} leads={leads} />
          </TabPanel>
          <TabPanel value={value} index={1} className="dashboard01_tabpanel">
            <Current setValue={setValue} value={value} />
          </TabPanel>
          <TabPanel value={value} index={2} className="dashboard01_tabpanel">
            <Form setValue={setValue} value={value} leads={leads} />
          </TabPanel>
          <TabPanel value={value} index={3} className="dashboard01_tabpanel">
            <Revenues setValue={setValue} value={value} />
          </TabPanel>
          <TabPanel value={value} index={4} className="dashboard01_tabpanel">
            <Invoices setValue={setValue} value={value} />
          </TabPanel>
        </div>
      </Box>
    </div>
  );
}
